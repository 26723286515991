<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #577eba">สร้างวีดีโอ</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <!-- <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col> -->
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มวีดีโอ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12">
        <v-card outlined class="pa-4">
          <v-row align="center" justify="center">
            
            <img :src="imgUrl" width="50%" class="pa-4" />
          </v-row>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersBrand"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            loading-text="กำลังโหลดข้อมูล"
          >
            <template v-slot:[`item.banner_url`]="{ item }">
              <img :src="item.banner_url" width="100px" class="ma-1" />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                
                <v-icon @click="UpdateBanner(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon>
              </v-row>
            </template>
            <!-- <template v-slot:body="props" >
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td>
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-center">{{ item.sortindex }}</td>
                  <td class="text-center">{{ item.video_name }}</td>
                  <td class="text-center">{{ item.video_desc }}</td>
                  <td class="text-center">{{ item.keyVdo }}</td>
                  <td class="text-center">{{ item.create_by }}</td>
                  <td class="text-center">{{ item.update_by }}</td>
                  
                  <td class="text-center">
                    <v-row justify="center">
                      <v-icon @click="ViewBanner(item)">mdi-eye</v-icon>
                      <v-icon @click="UpdateBanner(item)" class="mx-2"
                        >mdi-pencil</v-icon
                      >
                      <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template> -->
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrand: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับ", value: "sortindex", align: "center" },
        { text: "ชื่อวีดีโอ", value: "video_name", align: "center" },
        { text: "รายละเอียด", value: "video_desc", align: "center" },
        { text: "ลิงค์", value: "keyVdo", align: "center" },
        { text: "สร้างโดย", value: "create_by", align: "center" },
        { text: "แก้ไขโดย", value: "update_by", align: "center" },

        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userPoliceWeb"))
    );
    this.getAllbanner();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userPoliceWeb"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/video/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async getAllbanner() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/video`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      console.log("itemBefore", this.items);
      console.log("itemAfter", this.items);
      this.loading = false;
    },
    UpdateBanner(val) {
      console.log("val", val);
      localStorage.setItem("videodata", Encode.encode(val));
      this.$router.push("EditBanners_10");
    },
    ViewBanner(val) {
      localStorage.setItem("videodata", Encode.encode(val));
      this.$router.push("ViewBanners_10");
    },
    async DeleteBanner(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/video/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanner();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbanners_10");
    },
  },
};
</script>