<template>
  <div>
    <ManageBanners_10 />
  </div>
</template>
<script>
import ManageBanners_10 from "@/components/Banners_10/ManageBanners_10";
export default {
  components: {
    ManageBanners_10,
  },
  created() {
  },
};
</script>
